import React from "react";
import ListLanguages from "../Languages/ListLanguages";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from 'Hoc/Template';
import Online from "./Online";

const propTypes = {
    changeLanguages: PropTypes.func,
    t: PropTypes.func.isRequired,
    consultantStatus: PropTypes.bool.isRequired
};

const HeaderBottom = ({changeLanguages, consultantStatus, t}) => {
    return (
        <div className="main__online">
            <svg width="100%" height="100%" viewBox="0 0 425 47" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <path d="M83.5 40.5C61.1 40.5 5 44.1667 0 46.5V0.5H425V46.5C422.167 39.8333 410.5 40.5 386.5 40.5C356.5 40.5 337.5 46.5 322 46.5C306.5 46.5 267.5 40.5 243.5 40.5C219.5 40.5 184.5 46.5 154.5 46.5C124.5 46.5 111.5 40.5 83.5 40.5Z" fill="url(#paint0_linear)"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="11.5" y1="-26.9999" x2="438.5" y2="49.0001" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0754206" stopColor="#127680"/>
                        <stop offset="1" stopColor="#2EE180"/>
                    </linearGradient>
                </defs>
            </svg>
            <div className="main__online-block">
                <div className="main__online-item">
                    <Online consultantStatus={consultantStatus}/>
                    <span>{t('Тинер на связи')}</span>
                </div>
                {!!changeLanguages && <ListLanguages changeLanguages={changeLanguages} /> }
            </div>
        </div>
    );
};

HeaderBottom.propTypes = propTypes;

export default compose(withTagDefaultProps())(HeaderBottom);