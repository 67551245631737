import Constants from '../Constants';
import { clientCookies, consultationCookies } from 'Services/Cookie';

export const pageLoaded = (dispatch) => async () => {
    dispatch({ type: Constants.PAGE_SUCCESSED, data: {} });
};

export const pageLoading = (dispatch) => () => {
    const clientId = clientCookies.get() || '';
    const consultationId = consultationCookies.get() || '';

    dispatch({
        type: Constants.CHANGE_USER_DATA,
        data: { id: clientId, consultationId: consultationId },
    });
};

export const pageFailed = (dispatch) => () => dispatch({ type: Constants.PAGE_FAILED });

export const changeTemporaryMessage = (dispatch) => (temporaryMessage) => {
    dispatch({
        type: Constants.CHANGE_TEMPORARY_MESSAGE,
        data: { temporaryMessage },
    });
};

export const clearMessage = (dispatch) => () =>
    dispatch({
        type: Constants.CLEAR_MESSAGE,
        data: { temporaryMessage: '' },
    });

export const changeRoute = (dispatch) => (url) => {
    dispatch({
        type: Constants.CHANGE_PAGE,
        data: { url },
    });
};

export const changeCloseChat = (dispatch) => (closeChat) =>
    dispatch({
        type: Constants.CLOSE_CHAT,
        data: { closeChat },
    });

export const changeCloseStar = (dispatch) => (closeStar) =>
    dispatch({
        type: Constants.CLOSE_STAR,
        data: { closeStar },
    });

export const changeStar = (dispatch) => (star) =>
    dispatch({
        type: Constants.CHANGE_STAR,
        data: { star },
    });
