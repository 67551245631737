import { withTranslation } from 'Services/i18n';
import { connect } from 'react-redux';

const DefaultProps = (props, actions) => (Views) => {
    let _data = {};

    const getProps = (...data) => {
        props = props || (() => ({}));
        _data = props(...data);
        return _data;
    };

    const getActions = (dispatch, ownProps) => {
        actions = actions || (() => {});

        return {
            ...actions(dispatch, { ...ownProps, ..._data }),
            t: withTranslation,
        };
    };

    return connect(getProps, getActions)(Views);
};

export default DefaultProps;
