import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTagDefaultProps } from 'Hoc/Template';
import { withServiceConsumer } from 'Services/Context';
import { languageOptions, languageList, withOptionLabelTranslation } from 'Services/enum';
import { identifyUserLocation } from 'Services';
import { Input } from 'Templates/Input';
import { isEmail } from 'validator';

const isEmailWithoutEnglishLetters = (email) => /([a-z0-9]|\s|\p{S}|\p{P})+@/gu.test(email)

const propTypes = {
    onChange: PropTypes.func.isRequired,
    changeRoute: PropTypes.func.isRequired,
    submitClientForm: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lang: PropTypes.number.isRequired,
    agree: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

const Form = ({ name, onChange, email, lang, agree, changeRoute, t, submitClientForm }) => {
    const checkForm = () => !(name && isEmailWithoutEnglishLetters(email) && isEmail(email) && lang && agree);

    const onSubmit = async () => {
        const location = await identifyUserLocation();

        const payload = {
            name,
            email,
            language: lang,
            sex: 3,
            age: 1,
            country: '',
            region: '',
            city: '',
            ...location,
        };

        const submit = async () => await submitClientForm(payload);

        switch (lang) {
            case languageList.kaz:
            case languageList.kgz:
                await submit();
                return changeRoute('formLater');

            default:
                await submit();
                return changeRoute('chat');
        }
    };

    const _onChange = (key) => (data) => onChange(key, data);

    return (
        <form className="form">
            <div className="form__item">
                <div className="form__block">
                    <label htmlFor="name" className="form__label">
                        {t('Как тебя зовут?')}
                    </label>
                    <Input
                        className="form__input"
                        id="name"
                        type="text"
                        placeholder={t('Твое имя')}
                        value={name}
                        onChange={_onChange('name')}
                    />
                </div>
                <div className="form__block">
                    <label htmlFor="email" className="form__label">
                        {t('Електронная почта')}
                    </label>
                    <Input
                        className="form__input"
                        type="email"
                        id="email"
                        placeholder={t('Введи свой почтовый ящик')}
                        value={email}
                        onChange={_onChange('email')}
                    />
                </div>
                <div className="form__block">
                    <label htmlFor="lang" className="form__label">
                        {t('На каком языке тебе удобнее говорить?')}
                    </label>
                    <Input
                        type="select"
                        className="form__input form__input-no-border"
                        placeholder={t('Выбери свой язык')}
                        id="lang"
                        value={lang}
                        onChange={_onChange('lang')}
                        options={withOptionLabelTranslation(languageOptions)}
                    />
                </div>
            </div>
            <div className="form__item">
                <input
                    className="form__checkbox"
                    type="checkbox"
                    id="agree"
                    checked={agree}
                    onChange={() => onChange('agree', !agree)}
                />
                <label className="form__label" htmlFor="agree">
                    {t('Согласен с')}
                    &nbsp;
                    <span className="form__link" onClick={() => changeRoute('regulations')}>
                        {t('правилами')}
                    </span>
                </label>
            </div>
            <button className="form__btn" type="button" disabled={checkForm()} onClick={onSubmit}>
                {t('Отправить')}
            </button>
        </form>
    );
};

Form.propTypes = propTypes;

const mapDispatchToProps = (dispatch, ownProps) => {
    const { getActionStore } = ownProps.service;

    return {
        submitClientForm: getActionStore('submitClientForm')(dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(null, mapDispatchToProps))(Form);
