import React from 'react';
import { compose } from 'redux';
import logo from 'Assets/images/logo.png';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';

const propTypes = {
    changeCloseChat: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const HeaderSmallIndex = ({ changeCloseChat, t }) => {
    return (
        <div className="main__header">
            <div className="main__header-item">
                <img className="main__header-img" src={logo} alt={t('Логотип')} />
                <span>{t('Teenergizer')}</span>
            </div>
            <div className="main__header-item" onClick={changeCloseChat}>
                <div className="main__header-block">
                    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.60778 8.0908L13.7485 1.86147C14.0838 1.4988 14.0838 0.944135 13.7485 0.602802C13.4132 0.261468 12.8473 0.261468 12.512 0.602802L7 6.21347L1.48802 0.602802C1.13174 0.261468 0.586826 0.261468 0.251497 0.602802C-0.0838323 0.944135 -0.0838323 1.4988 0.251497 1.86147L6.37126 8.0908C6.72755 8.43213 7.27246 8.43213 7.60778 8.0908Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

HeaderSmallIndex.propTypes = propTypes;

export default compose(withTagDefaultProps())(HeaderSmallIndex);
