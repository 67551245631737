const Constants = {
    PAGE_LOADED: 'FORM_REVIEW_LOADED',
    PAGE_UNLOADED: 'FORM_REVIEW_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'FORM_REVIEW_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'FORM_REVIEW_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'FORM_REVIEW_FETCH_ITEMS_FAILED',

    CHANGE_FORM: 'FORM_REVIEW_CHANGE_FORM',

    CHANGE_PAGE: 'CHANGE_PAGE',

    CHANGE_REVIEW: 'CHANGE_REVIEW',
};

export default Constants;
