import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import logo from 'Assets/images/logo.png';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';

const propTypes = {
    changeRoute: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    changeTemporaryMessage: PropTypes.func.isRequired,
};

const FormLater = ({ changeRoute, t, changeTemporaryMessage }) => {
    const onClick = () => {
        changeTemporaryMessage('');
        changeRoute('chatSmall');
    };

    return (
        <div className="main">
            <div className="main__wrapper main__waiting">
                <div className="main__waiting-item main__waiting-center">
                    <img className="main__header-img" src={logo} alt={t('Логотип')} />
                </div>
                <div className="main__waiting-item">
                    <h3 className="main__waiting-title">
                        {t('К сожалению на выбраном вами языке, недоступны консультанты')}
                    </h3>
                </div>

                <div className="main__waiting-item">
                    <h3 className="main__waiting-title">{t('Мы с вами свяжемся!!!')}</h3>
                </div>

                <div className="main__waiting-item">
                    <button className="form__btn" type="submit" onClick={onClick}>
                        {t('Вернутся на чат')}
                    </button>
                </div>
            </div>
        </div>
    );
};

FormLater.propTypes = propTypes;

const mapDispatchToProps = (dispatch, { service: { getActionAppStore } }) => ({
    changeRoute: getActionAppStore('changeRoute')(dispatch),
    changeTemporaryMessage: getActionAppStore('changeTemporaryMessage')(dispatch),
});

export default compose(withServiceConsumer, withTagDefaultProps(null, mapDispatchToProps))(FormLater);
