import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppActions} from 'App/Action';

const PAGE = {
    INIT: 0,
    LOADING: 1,
    FINISH: 2,
}

const LoadPage = (WrappedComponent) =>
    class extends Component {

        constructor(props) {
            super(props);

            this.state = {
                pageLoad: PAGE.INIT
            };
        }

        async pageLoading(props) {
            if (this.state.pageLoad) {
                return;
            }

            this.state.pageLoad = PAGE.LOADING;
            props.pageLoading();

            await props.onLoad();
            await props.pageLoaded();

            this.setState({ pageLoad: PAGE.FINISH });
        }

        componentWillMount() {
            this.pageLoading(this.props);
        }

        componentWillUnmount() {
            if (this.props.onUnload) {
                this.props.onUnload();
            }
        }

        render() {
            if (this.state.pageLoad === PAGE.FINISH) {
                return <WrappedComponent key={this.props.langPage}  {...this.props} />;
            }

            return null;
        }
    };

const mapStateToProps = (state) => ({
    langPage: state.appState.langPage,
});

const mapDispatchToProps = (dispatch) => ({
    pageLoading: AppActions.pageLoading(dispatch),
    pageLoaded: AppActions.pageLoaded(dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), LoadPage);
