const Constants = {
    PAGE_LOADING: 'PAGE_LOADING',
    PAGE_SUCCESSED: 'PAGE_SUCCESSED',
    PAGE_FAILED: 'PAGE_FAILED',

    CHANGE_TEMPORARY_MESSAGE: 'CHAT_SMALL_CHANGE_TEMPORARY_MESSAGE',

    CHANGE_PAGE: 'CHANGE_PAGE',

    CHANGE_STAR: 'CHANGE_STAR',

    RESIZE_CHAT: 'RESIZE_CHAT',

    CLOSE_CHAT: 'CLOSE_CHAT',

    CLOSE_STAR: 'CLOSE_STAR',

    CLEAR_MESSAGE: 'CLEAR_MESSAGE',

    /////// TODO: remove it later

    CHANGE_FORM: 'FORM_PAGE_CHANGE_FORM',
    CHANGE_USER_DATA: 'CHANGE_USER_DATA',
    SEND_MESSAGE: 'SEND_MESSAGE',
    CHANGE_LANG: 'CHANGE_LANG',
};

export default Constants;
