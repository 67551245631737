import { Socket } from './Socket';

const socketRequest = new Socket(`wss://${process.env.HOST_NAME}:7000`);

const urls = ['chat', 'notification'];
const mockUrls = ['room', 'connection'];

const cashRequest = new Promise(async (resolve) => {
    await Promise.all(urls.map((url) => socketRequest.load(url)));
    await Promise.all(mockUrls.map((url) => socketRequest.loadMock(url)));
    resolve(socketRequest);
});

export default cashRequest;
