import { compose, createStore } from 'redux';
import { reducers } from '../Reducers';

let composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export const store = createStore(reducers, composeEnhancers());
