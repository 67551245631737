import Constants from '../Constants';
import { mergeDeepLeft } from 'ramda';

export const initialState = {
    id: '',
    consultationId: '',
    consultantStatus: true,
    name: '',
    email: '',
    lang: 1,
    agree: false,
    temporaryMessage: '',
    star: '3',
};

export default function userReducer(state = initialState, action) {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const set = mergeDeepLeft({
                [data.key]: data.value,
            });
            const newState = set(state);

            return {
                ...state,
                ...newState,
            };

        case Constants.CHANGE_USER_DATA:
            return {
                ...state,
                ...data,
            };

        case Constants.CHANGE_TEMPORARY_MESSAGE:
        case Constants.CLEAR_MESSAGE:
            return {
                ...state,
                ...data,
            };

        case Constants.CHANGE_STAR:
            return {
                ...state,
                ...data
            }

        case Constants.CHANGE_PAGE:
            if (data.url !== 'chatSmall') return state;
            return {...initialState}

        default:
            return state;
    }
}
