import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.shape({ }),
};

const defaultProps = {
    disabled: false,
    placeholder: '',
    id: '',
    value: '',
    inputProps: { },
};

const SelectEnum = ({ id, disabled, value, placeholder, options, onChange, inputProps }) => {
    
    return (
        <Select
            className="custom-select select-custom-wrap"
            classNamePrefix="custom-select"
            id={id}
            isDisabled={disabled}
            value={options.reduce((acc, option) => {
                if (option.value) {
                    if (option.value === value) {
                        return [...acc, option];
                    }
                    return acc;
                }
                if (option.options && Array.isArray(option.options)) {
                    const result = option.options.find((item) => item.value === value);
                    if (typeof result !== 'undefined') {
                        return [...acc, result];
                    }
                }
                return acc;
            }, [])}
            onChange={(value) => onChange(value.value)}
            options={options}
            placeholder={placeholder}
            {...inputProps}
        />
    );
};

SelectEnum.propTypes = propTypes;
SelectEnum.defaultProps = defaultProps;

export default SelectEnum
