import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import logo from 'Assets/images/logo.png';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import Form from './Section/Form';
import ListLanguages from 'Templates/Languages/ListLanguages';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    changeRoute: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lang: PropTypes.number.isRequired,
    agree: PropTypes.bool.isRequired,
    changeLanguages: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const WaitingForm = ({ onChange, name, email, lang, agree, changeRoute, changeLanguages, t }) => {
    return (
        <div className="main">
            <div className="main__wrapper main__waiting">
                <div className="main__waiting-item main__waiting-center">
                    <img className="main__header-img" src={logo} alt={t('Логотип')} />
                    <div className="main__header-lang">
                        <ListLanguages changeLanguages={changeLanguages} />
                    </div>
                </div>
                <div className="main__waiting-item">
                    <span className="main__waiting-text">
                        {t(`Наши консультанты уже в пути🚀, пожалуйста, оставь актуальную почту, и наши супергерои ответят тебе!🙏`)}
                    </span>
                </div>
                <Form
                    name={name}
                    onChange={onChange}
                    email={email}
                    lang={lang}
                    agree={agree}
                    changeRoute={changeRoute}
                />
            </div>
        </div>
    );
};

WaitingForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getUserStoreItem } = ownProps.service;

    return {
        name: getUserStoreItem(state, 'name'),
        email: getUserStoreItem(state, 'email'),
        lang: getUserStoreItem(state, 'lang'),
        agree: getUserStoreItem(state, 'agree'),
    };
};

const mapDispatchToProps = (dispatch, { service: { getActionStore, getActionAppStore } }) => ({
    onChange: getActionStore('onChange')(dispatch),
    changeLanguages: getActionStore('changeLanguages')(dispatch),
    changeRoute: getActionAppStore('changeRoute')(dispatch),
});

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps))(WaitingForm);
