import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    consultantStatus: PropTypes.bool.isRequired
};

const Online = ({consultantStatus}) => {
    return (
        consultantStatus ?
                <svg className="main__online-login" width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#38ec34"/>
                </svg> :
                <svg className="main__online-login" width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill="#c7c7c7"/>
                </svg>
    )
}

Online.propTypes = propTypes;

export default Online;