import React, { useState } from 'react';
import { compose } from 'redux';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';
import FooterIndex from 'Templates/Footer/FooterIndex';
import UploadFileBlock from './UploadFileBlock';
import { Attached } from 'Templates/Attached';

const propTypes = {
    sendFiles: PropTypes.func.isRequired,
    changeCloseChat: PropTypes.func.isRequired,
    closeChat: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
};

const initialState = { message: '', files: [] };

const ChatForm = ({ sendFiles, id, clientId, changeCloseChat, closeChat, service }) => {
    const [state, setState] = useState(initialState);
    const { message, files } = state;
    const { socketService } = service;

    const sendMessage = async (data) => {
        const req = await socketService();
        await req.newMessage(data);
    };

    const sendTypingEvent = async ({ id, text }) => {
        const req = await socketService();
        await req.typingEvent({ id, text });
    };

    const onChange = (key, value) => setState({ ...state, [key]: value });
    const sendHandler = async () => {
        if (message.trim()) {
            await sendMessage({ id, text: message.trim(), client_id: clientId });
        }
        setState(initialState);
    };

    const sendFileMessage = async (params) => {
        await sendMessage(params);
    };

    const submit = async (e) => {
        if (files.length > 0) {
            sendFiles({ files, clientId }, `consultations/${id}/messages`).then((data) =>
                sendFileMessage({ id, files: data, client_id: clientId }),
            );
        }
        sendHandler();
    };

    const _onChange = (key, value) => {
        onChange(key, value);
        sendTypingEvent({ id, text: value });
    };

    const deleteFile = (index) => {
        const _files = files.filter((i, key) => key !== index);
        onChange('files', _files);
    };

    const disabledMessage = !!message.trim() || files.length > 0;

    return (
        <>
            <Attached files={files} onClickDelete={deleteFile} />
            <FooterIndex
                message={message}
                onChange={_onChange}
                onSubmit={submit}
                uploadComponent={<UploadFileBlock onChange={(value) => onChange('files', value)} files={files} />}
                changeCloseChat={changeCloseChat}
                onClick={() => {}}
                disabled={disabledMessage}
                closeChat={closeChat}
            />
        </>
    );
};

ChatForm.propTypes = propTypes;

const mapStateToProps = (state, { service }) => {
    const { getUserStoreItem } = service;
    return {
        id: getUserStoreItem(state, 'consultationId', ''),
        clientId: getUserStoreItem(state, 'id', ''),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        sendFiles: getActionStore('sendFiles')(dispatch, service),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps))(ChatForm);
