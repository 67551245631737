import { clientCookies, consultationCookies } from 'Services/Cookie';
import Constants from '../Constants';
import { createClientRequest, createConsultationRequest } from '../Services';

export const pageLoad = (dispatch) => async () => {
    const data = {};

    dispatch({
        type: Constants.PAGE_LOADED,
        data,
    });
};

export const onChange = (dispatch) => (key, value) => {
    dispatch({
        type: Constants.CHANGE_FORM,
        data: { key, value },
    });
};

export const submitClientForm = (dispatch) => async (data) => {
    const { id: clientId } = await createClientRequest(data);
    const { id: consultationId } = await createConsultationRequest({ clientId });

    clientCookies.delete();
    consultationCookies.delete();

    clientCookies.set(clientId);
    consultationCookies.set(consultationId);

    dispatch({
        type: Constants.CHANGE_USER_DATA,
        data: { id: clientId, consultationId: consultationId },
    });
};

export const changeLanguages = (dispatch) => (langPage) => {
    dispatch({
        type: Constants.CHANGE_LANG,
        data: { langPage },
    });
};
