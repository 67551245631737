import $ from "jquery";

const ukrainialLanguageRegex = /^[а-яА-ЯіїєґІЇЄҐ' ]*$/;

const getGoogleLocation = async (lat, lon) => {
    const googleApi = 'https://maps.googleapis.com/maps/api/geocode/json';
    const googleKey = process.env.GOOGLE_KEY;
    const url = `${googleApi}?latlng=${lat},${lon}&sensor=false&key=${googleKey}&language=uk`;
    
    const data = await $.ajax({ type: 'GET', url });
    if (data.status !== 'OK' || !Array.isArray(data.results)) {
        throw new Error('Failed');
    }

    const addresses = data.results.map(result => aggregateAddressComponents(result.address_components));
    const address = aggregateAddress(addresses);

    function aggregateAddress(addresses) {
        const keys = ['city', 'country', 'region'];
        const address = addresses.reduce((acc, address) => {
            keys.forEach(key => {
                const value = address[key];
                if (!value || !ukrainialLanguageRegex.test(value)) return;
                acc[key] = value;
            });

            return acc;
        });
        return address;
    }

    function aggregateAddressComponents(components) {
        const cityType = 'locality';
        const regionType = 'administrative_area_level_1';
        const countryType = 'country';

        const aggData = components.reduce((acc, component) => {
            if (hasType(component.types, cityType)) {
                acc.city = component.long_name;
            } else if (hasType(component.types, regionType)) {
                acc.region = component.long_name;
            } else if (hasType(component.types, countryType)) {
                acc.country = component.long_name;
            };

            return acc;
        }, {});

        return aggData;
    }

    function hasType(types, locationType) {
        return types.findIndex(type => locationType === type) > -1
    }

    return address;
}

export const identifyUserLocation = async () => {
    let location = { country: '', city: '', region: '' };
    try {
        const {country_name, region, city, latitude, longitude} = await $.ajax({
            url: 'https://json.geoiplookup.io',
            type: 'GET'
        });
        if (country_name) location.country = country_name;
        if (region) location.region = region;
        if (city) location.city = city;

        if (!latitude || !longitude) throw new Error('Missed coordinate');

        const googleLocation = await getGoogleLocation(latitude, longitude);
        location = {...location, ...googleLocation};
    } catch(e) {
        // ignore error
    }

    return location;
}
