import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    item: PropTypes.object.isRequired,
};

const MessageBlock = ({ item }) => {
    return (
        <div className={`main__body-message ${item.client_id ? '' : 'main__body-consultant-message'}`}>
            <span dangerouslySetInnerHTML={{ __html: item.text.replace(/\n/g, '<br />') }} />
        </div>
    );
};

MessageBlock.propTypes = propTypes;

export default MessageBlock;
