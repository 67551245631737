import { request } from 'Services';

const save = (data, endpointUrl) => {
    const sendObj = {
        url: endpointUrl,
        data,
        type: 'POST',
    };
    return request.sendRequest(sendObj, false);
};

const requestCreate = requestUrl => data => {

    return new Promise((resolve, reject) => {
        save(data, requestUrl).then(
            (res) => resolve({ ...res[0] }),
            (error) => reject(error),
        );
    });
};

export const saveClientReview = (id) => requestCreate(`consultations/${id}/client-review`);
