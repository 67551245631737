import React from 'react';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '../Error';
import { store } from './Store';

const Index = ({ children }) => {
    return (
        <Provider store={store}>
            <ErrorBoundary>{children}</ErrorBoundary>
        </Provider>
    );
};

export default Index;
