// import { numberWithCommas } from 'Services'
import { t } from 'Services/i18n';

export const withOptionLabelTranslation = (options) => options.map(option => ({...option, label: t(option.label)}))

const getListValuesWrapper = (list) => {
    list.getListValues = (banList = []) => list.filter((el) => !banList.includes(el.value)).map((el) => el.value);
    return list;
};

export const consultationTypeOptions = [
    { value: 1, label: t('Primary') },
    { value: 2, label: t('Repeated') },
    { value: 3, label: t('Regular') },
];

export const consultationTopicOptions = [
    { value: 1, label: t('Personal relationships') },
    { value: 2, label: t('HIV') },
    { value: 3, label: t('SEX') },
    { value: 4, label: t('Bullying') },
    { value: 5, label: t('Discrimination') },
    { value: 6, label: t('Depressed state') },
    { value: 7, label: t('Domesctic violence') },
    { value: 8, label: t('COVID-19') },
    { value: 9, label: t('Otha') },
];

export const consultationActionsOptions = [
    { value: 1, label: t('About consultation') },
    { value: 2, label: t('Information of topic') },
    { value: 3, label: t('Psychological support') },
    { value: 4, label: t('Advice on legal question') },
    { value: 5, label: t('Oth') },
    { value: 6, label: t('Redirect') },
];

export const knowSourceOptions = [
    { value: 1, label: t('Teenergizer training') },
    { value: 2, label: t('Study college') },
    { value: 3, label: t('Internet info') },
    { value: 3, label: t('Personal experience') },
    { value: 3, label: t('Other') },
    { value: 3, label: t('Cources rquired Teenergizer') },
];

export const countryOptions = [
    { value: 1, label: t('Austria') },
    { value: 2, label: t('Azerbaijan') },
    { value: 3, label: t('Albania') },
    { value: 4, label: t('Armenia') },
    { value: 5, label: t('Belarus') },
    { value: 6, label: t('Great Britain') },
    { value: 7, label: t('Georgia') },
    { value: 8, label: t('Denmark') },
    { value: 9, label: t('Canada') },
    { value: 10, label: t('Kazakhstan') },
    { value: 11, label: t('Kyrgyzstan') },
    { value: 12, label: t('Latvia') },
    { value: 13, label: t('Lithuania') },
    { value: 14, label: t('Moldova') },
    { value: 15, label: t('Germany') },
    { value: 16, label: t('Norway') },
    { value: 17, label: t('Poland') },
    { value: 18, label: t('Russia') },
    { value: 19, label: t('Slovakia') },
    { value: 20, label: t('USA') },
    { value: 21, label: t('Tajikistan') },
    { value: 22, label: t('Turkey') },
    { value: 23, label: t('Uzbekistan') },
    { value: 24, label: t('Ukraine') },
    { value: 25, label: t('France') },
    { value: 26, label: t('Czech Republic') },
    { value: 27, label: t('Sweden') },
    { value: 28, label: t('Other') },
]

export const ageOptions = [
    { value: 1, label: '12-15' },
    { value: 2, label: '16-19' },
    { value: 3, label: '20-24' },
    { value: 4, label: '25-30' },
];

export const consultationStatus = {
    idle: 20,
    open: 50,
    active: 100,
    close: 0,
};

export const consultationStatusOptions = getListValuesWrapper([
    { value: consultationStatus.active, label: t('Active'), color: 'gray' },
    { value: consultationStatus.open, label: t('Open'), color: 'red' },
    { value: consultationStatus.idle, label: t('Idle'), color: 'yellow' },
    //{ value: 0, label: t('Close'), color: 'white' },
]);

export const genderOptions = [
    { value: 1, label: t('Женщина') },
    { value: 2, label: t('Мужчина') },
    { value: 3, label: t('Другое') },
];

export const languageList = {
    ukr: 1,
    rus: 2,
    kgz: 3,
    kaz: 4,
};

export const languageOptions = [
    { value: languageList.ukr, label: 'Украинский' },
    { value: languageList.rus, label: 'Русский' },
    { value: languageList.kgz, label: 'Киргистанский' },
    { value: languageList.kaz, label: 'Казахский' },
];

export const rowsPerPageOptions = [
    { value: '10', label: 10 },
    { value: '20', label: 20 },
    { value: '50', label: 50 },
    { value: '100', label: 100 },
];

export const roleOptions = [
    { value: 'ROLE_ADMIN', label: t('Admin') },
    { value: 'ROLE_OPERATOR', label: t('Operator') },
    { value: 'ROLE_CONSULTANT', label: t('Consultant') },
];

export const onlineStatusList = [
    { label: 'online', value: '10', color: 'green' },
    { label: 'offline', value: '0', color: 'red' },
];
