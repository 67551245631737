import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    icon: PropTypes.objectOf(PropTypes.any).isRequired,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const Svg = ({ icon, className, width, height }) => {
    const w = width || icon.viewBox.split(" ")[2];
    const h = height || icon.viewBox.split(" ")[3];

    return (
        <svg className={`icon-svg ${className}`} width={w} height={h}>
            <use xlinkHref={icon.url} viewBox={icon.viewBox} />
        </svg>
    );
};

Svg.propTypes = propTypes;
Svg.defaultProps = defaultProps;

export default Svg;
