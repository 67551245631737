import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Pages from './Pages';

const rootElement = document.createElement('div');
rootElement.id = process.env.PRESENT_NAME;
document.body.appendChild(rootElement)

ReactDOM.render(
    <React.StrictMode>
        <App>
            <Pages/>
        </App>
    </React.StrictMode>,
    rootElement,
);
