import React from 'react';
import OpenMessageButton from './OpenMessageButton';
import SendMessageButton from './SendMessageButton';
import PropTypes from 'prop-types';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    changeCloseChat: PropTypes.func.isRequired,
    closeChat: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
};


const ButtonWrapper = ({onClick, disabled, closeChat, changeCloseChat}) => {

    return (
        closeChat ? <OpenMessageButton onClick={changeCloseChat} /> : <SendMessageButton disabled={disabled} onClick={onClick}/>
    );
};

ButtonWrapper.propTypes = propTypes;

export default ButtonWrapper;