import socketRequest from 'Services/Socket';

export const socketService = async () => {
    const { api, join } = await socketRequest;

    return {
        chatOn(name, call) {
            api.chat.on(name, call);
        },
        remove(name, call) {
            api.chat.remove(name, call);
        },
        async newMessage(data) {
            const res = await api.chat.send(data);
            return res;
        },
        async typingEvent(data) {
            const res = await api.chat.enter(data);
            return res;
        },
        async notificationOn(data) {
            const res = await join(data);
            return res;
        },
        async getInfo(data) {
            const res = await api.chat.info(data);
            return res;
        },
    };
};
