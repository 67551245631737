import {MessageError} from './MessageError';
import {MessageInterface} from './MessageInterface';

export class Socket {
    constructor(url) {
        this.init = this.init.bind(this);
        this.close = this.disconnect.bind(this);
        this.message = this.message.bind(this);
        this.ready = this.ready.bind(this);
        this.httpCall = this.httpCall.bind(this);
        this.socketCall = this.socketCall.bind(this);
        this.join = this.join.bind(this);
        this.leave = this.leave.bind(this);

        this.rooms = [];
        this.url = url;
        this.socket = null;
        this.api = {};
        this.callId = 0;
        this.calls = new Map();
        this.init();

        this.recall = null;
    }

    join(data) {
        this.rooms.push(data);
        return this.api.notification.on(data);
    }

    leave(data) {
        this.rooms = this.rooms.filter(({id}) => id !== data.id);
        return this.api.notification.off(data);
    }

    init() {
        this.socket = new WebSocket(this.url);
        this.socket.addEventListener('message', ({ data }) => {
            this.message(data);
        });
    }

    message(data) {
        let packet;
        try {
            packet = JSON.parse(data);
        } catch (err) {
            console.error(err);
            return;
        }
        const [callType, target] = Object.keys(packet);
        const callId = packet[callType];
        const args = packet[target];
        if (callId && args !== undefined) {
            if (callType === 'callback') {
                const promised = this.calls.get(callId);
                if (!promised) return;

                const [resolve, reject] = promised;

                if (packet.error) {
                    const { message, code } = packet.error;
                    const error = new MessageError(message, code);

                    reject(error);
                    return;
                }

                resolve(args);
                return;
            }
            if (callType === 'event') {
                const [interfaceName, eventName] = target.split('/');
                const metacomInterface = this.api[interfaceName];
                metacomInterface.emit(eventName, args);
            }
        }
    }

    decode(data) {
        return data;
    }

    encode(data) {
        return data;
    }

    ready() {
        if (!this.recall) {
            this.recall = new Promise((resolve) => {
                const {readyState} = this.socket;
                if (readyState === WebSocket.OPEN) return resolve();
                if (readyState !== WebSocket.CONNECTING) this.init();
                this.socket.addEventListener('open', resolve);
            }).then(() => {
                this.rooms.map(this.api.notification.on);
                this.recall = null
            });
        }

        return this.recall;
    }

    async load(...interfaces) {
        const introspect = this.httpCall('system')('introspect');
        const introspection = await introspect(interfaces);
        const available = Object.keys(introspection);

        for (const interfaceName of interfaces) {
            if (!available.includes(interfaceName)) continue;

            const methods = new MessageInterface();
            const iface = introspection[interfaceName];
            const request = this.socketCall(interfaceName);
            const methodNames = Object.keys(iface);

            for (const methodName of methodNames) {
                methods[methodName] = request(methodName);
            }

            this.api[interfaceName] = methods;
        }
    }

    async loadMock(...interfaces) {
        for (const interfaceName of interfaces) {
            this.api[interfaceName] = new MessageInterface();
        }
    }

    httpCall(iname, ver) {
        return (methodName) =>
            (args = {}) => {
                const callId = ++this.callId;
                const interfaceName = ver ? `${iname}.${ver}` : iname;
                const target = interfaceName + '/' + methodName;
                const packet = { call: callId, [target]: args };
                const dest = new URL(this.url);
                const protocol = dest.protocol === 'ws:' ? 'http' : 'https';
                const url = `${protocol}://${dest.host}/api`;

                return fetch(url, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: this.encode(JSON.stringify(packet)),
                }).then((res) => {
                    const { status } = res;
                    if (status === 200) return res.json().then(({ result }) => result);
                    throw new Error(`Status Code: ${status}`);
                });
            };
    }

    socketCall(iname, ver) {
        return (methodName) =>
            async (args = {}) => {
                const callId = ++this.callId;
                const interfaceName = ver ? `${iname}.${ver}` : iname;
                const target = interfaceName + '/' + methodName;

                await this.ready();

                return new Promise((resolve, reject) => {
                    this.calls.set(callId, [resolve, reject]);
                    const packet = { call: callId, [target]: args };
                    this.socket.send(this.encode(JSON.stringify(packet)));
                });
            };
    }

    async disconnect() {
        await this.ready();
        this.socket.close();
    }
}
