import socketRequest from 'Services/Socket';

export const socketService = async () => {
    const { api, close } = await socketRequest;

    return {
        async getInfo(data) {
            const res = await api.chat.info(data);
            return res;
        },
        close,
    };
};
