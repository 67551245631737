import { combineReducers } from 'redux';
import appReducer from './AppReducer';
import userReducer from './UserReducer';
import pageReducer from './PageReducer';

// Combine all reducers you may have here
export const reducers = combineReducers({
    appState: appReducer,
    userState: userReducer,
    pageState: pageReducer,
});
