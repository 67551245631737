import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { LoadPage } from 'Hoc';
import { withTagDefaultProps } from 'Hoc/Template';
import { ServiceProvider } from 'Services/Context';
import Greeting from './Components/Greeting';
import * as service from './Services';

const propTypes = {
    messagesUpdate: PropTypes.func.isRequired,
    consultationId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    chatEventListener: PropTypes.func.isRequired,
    temporaryMessage: PropTypes.string.isRequired,
    changeTemporaryMessage: PropTypes.func.isRequired,
};

const Index = ({
    messagesUpdate,
    clientId,
    consultationId,
    chatEventListener,
    temporaryMessage,
    changeTemporaryMessage,
}) => {
    const { socketService } = service;
    const listenHandler = async ({ data }) => await messagesUpdate(data);

    useEffect(() => {
        const init = async () => {
            const emitter = await socketService();
            emitter.notificationOn({ id: consultationId });
            emitter.chatOn('send', listenHandler);
            emitter.chatOn('event', chatEventListener);
            if (temporaryMessage.trim()) {
                emitter.newMessage({ id: consultationId, text: temporaryMessage, client_id: clientId });
                changeTemporaryMessage('');
            }

            return emitter;
        };

        const emitter = init();

        return () => {
            emitter.then((data) => {
                data.remove('send', listenHandler);
                data.remove('event', chatEventListener);
            });
        };
    }, []);

    return (
        <ServiceProvider value={service}>
            <Greeting />
        </ServiceProvider>
    );
};

Index.propTypes = propTypes;

const mapStateToProps = (state) => {
    const { getUserStoreItem } = service;

    return {
        clientId: getUserStoreItem(state, 'id', ''),
        consultationId: getUserStoreItem(state, 'consultationId', ''),
        temporaryMessage: getUserStoreItem(state, 'temporaryMessage', ''),
    };
};

const mapDispatchToProps = (dispatch) => {
    const { getActionStore, getActionUserStore } = service;
    return {
        onLoad: getActionStore('pageLoad')(dispatch, service),
        messagesUpdate: getActionStore('messagesUpdate')(dispatch),
        chatEventListener: getActionStore('chatEventListener')(dispatch),
        changeTemporaryMessage: getActionUserStore('changeTemporaryMessage')(dispatch),
    };
};

export default compose(withTagDefaultProps(mapStateToProps, mapDispatchToProps), LoadPage)(Index);
