import replace from "./replace";
import { dictionary, settings } from "./settings";

const i18n = {
    translate: (word) => dictionary(word),

    language: function() {
        return this.settings.toString();
    },
    change: settings.change,
    settings: settings,
};

const withTranslation = (label) => i18n.translate(label.replaceAll(replace));
const t = i18n.translate;

export { withTranslation, i18n, t };
