import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isEmpty } from 'ramda';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import { TypingWrap } from 'Widgets/Typing';
import { FileBlock, MessageBlock } from 'Templates/Message';
import { formatDateTime } from 'Services';

const propTypes = {
    messages: PropTypes.arrayOf(PropTypes.any),
    service: PropTypes.shape({
        scroller: PropTypes.func.isRequired,
    }).isRequired,
};

const defaultProps = {
    messages: [],
};

const Chat = ({ messages, service }) => {
    const ref = useRef(null);
    const { scroller } = service;
    const isFile = (str) => str === 'file';
    const isYourMessage = message => Boolean(message?.client_id);

    const {scrollTop, clientHeight, scrollHeight} = ref.current || {};
    const value = Math.abs((scrollTop + clientHeight) - scrollHeight)
    const atTheBottom = value >= 0 && value <= 5;
    const shouldScroll = isYourMessage(messages.at(-1)) || atTheBottom;

    useLayoutEffect(() => {
        if (!shouldScroll) return;
        scroller(ref.current);
    });

    useLayoutEffect(() => scroller(ref.current), []);

    const onLoad = () => scroller(ref.current);

    return (
        <>
            <div className="main__body" ref={ref}>
                {!isEmpty(messages) &&
                    messages.map((item, index) => (
                        <div key={`${item.text}-${index}`} className="main__body-item">
                            <div className={`main__body-time ${item.client_id ? '' : 'main__body-consultant'}`}>
                                {formatDateTime(item.date)}
                            </div>
                            {isFile(item.type) ? <FileBlock item={item} onLoad={onLoad} /> : <MessageBlock item={item} />}
                        </div>
                    ))}
            </div>
            <div className="main__write">
                <TypingWrap />
            </div>
        </>
    );
};

Chat.propTypes = propTypes;
Chat.defaultProps = defaultProps;

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;

    return {
        messages: getStoreItem(state, 'messages.items', []),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, null))(Chat);
