import { getExpire } from 'Services';
import { CookieHandlers } from './CookieHandlers';

const cookieHandlers = new CookieHandlers();

const clientId = 'teenergizer_widget_client_id';
const consultationId = 'teenergizer_widget_consultation_id';

const initCookieMethods = (name, date) => ({
    get: () => cookieHandlers.get(name),
    set: (value) => cookieHandlers.set(name, value, date),
    delete: () => cookieHandlers.remove(name),
});

const clientCookies = initCookieMethods(clientId, getExpire(24 * 60));
const consultationCookies = initCookieMethods(consultationId, getExpire(60));

export { cookieHandlers, clientCookies, consultationCookies };
