import React from 'react';
import {compose} from "redux";
import Regulations from "./Components/Regulations";
import {ServiceProvider} from "Services/Context";
import { withTagDefaultProps } from 'Hoc/Template';
import * as service from "./Services";
import { LoadPage } from "Hoc";

const Index = () => {
    return (
        <ServiceProvider value={service}>
            <Regulations />
        </ServiceProvider>
    )
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: service.getActionStore('pageLoad')(dispatch, service),
});

export default compose(withTagDefaultProps(null, mapDispatchToProps), LoadPage)(Index);