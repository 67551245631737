import getStoreItem, {
    getActionStore,
    getActionAppStore,
    getActionUserStore,
    getAppStoreItem,
    getUserStoreItem,
} from './StoreService';
import { socketService } from './SocketService';
import { requestMessages, requestUploadFiles, createConsultationRequest } from './RequestService';

const scroller = (elem) => {
    if (!elem) return;
    elem.scrollTo(0, elem.scrollHeight);
};

export {
    getStoreItem,
    getActionStore,
    getActionAppStore,
    getActionUserStore,
    getAppStoreItem,
    getUserStoreItem,
    socketService,
    requestMessages,
    scroller,
    requestUploadFiles,
    createConsultationRequest,
};
