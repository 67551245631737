import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import logo from 'Assets/images/logo.png';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import { consultationCookies } from 'Services/Cookie';
import Review from './Section/Review';

const propTypes = {
    changeRoute: PropTypes.func.isRequired,
    changeReview: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    star: PropTypes.string.isRequired,
    review: PropTypes.string.isRequired,
    consultationId: PropTypes.string.isRequired,
};

const WaitingFormReview = ({
    consultationId,
    userId,
    changeRoute,
    changeReview,
    service: { saveClientReview },
    star,
    review,
    t,
}) => {
    const onSubmit = () => {
        const payload = { rating: star, description: review, clientId: userId };
        saveClientReview(consultationId)(payload);
        consultationCookies.delete();
        changeRoute('formAnswer');
    };

    useEffect(() => {
        const unmount = () => consultationCookies.delete();
        window.addEventListener('beforeunload', unmount);
        return () => {
            consultationCookies.delete();
        };
    }, []);

    return (
        <div className="main">
            <div className="main__wrapper main__waiting">
                <div className="main__waiting-item main__waiting-center">
                    <img className="main__header-img" src={logo} alt={t('Логотип')} />
                </div>
                <div className="main__waiting-item">
                    <h3 className="main__waiting-title">{t('Как прошла твоя консультация?')}</h3>
                </div>
                <div className="main__waiting-item">
                    <Review changeReview={changeReview} />
                </div>
                <div className="main__waiting-item">
                    <button className="form__btn" type="submit" disabled={!star} onClick={onSubmit}>
                        {t('Оставить отзыв')}
                    </button>
                </div>
                <div className="main__waiting-item">
                    <button
                        type="button"
                        className="main__waiting-skip"
                        onClick={() => {
                            changeRoute('chatSmall');
                        }}
                    >
                        {t('Пропустить')}
                    </button>
                </div>
            </div>
        </div>
    );
};

WaitingFormReview.propTypes = propTypes;

const mapStateToProps = (state, { service }) => {
    const { getStoreItem, getUserStateItem } = service;

    return {
        review: getStoreItem(state, 'review', ''),
        star: getUserStateItem(state, 'star', ''),
        consultationId: getUserStateItem(state, 'consultationId', ''),
        userId: getUserStateItem(state, 'id', ''),
    };
};

const mapDispatchToProps = (dispatch, { service: { getActionStore, getActionAppStore, saveConsultation } }) => ({
    changeRoute: getActionAppStore('changeRoute')(dispatch),
    changeReview: getActionStore('changeReview')(dispatch),
});

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(WaitingFormReview);
