import { clientCookies, consultationCookies } from 'Services/Cookie';
import Constants from '../Constants';

export const pageLoad = (dispatch, service) => async () => {
    const data = {};

    const { socketService } = service;
    const emitter = await socketService();
    await emitter.getInfo({ id: consultationCookies.get(), client_id: clientCookies.get() });
    emitter.close();

    clientCookies.delete();
    consultationCookies.delete();

    dispatch({
        type: Constants.PAGE_LOADED,
        data,
    });
};
