import React, {Fragment} from 'react';
import {compose} from 'redux';
import {withTagDefaultProps} from 'Hoc/Template';
import PropTypes from 'prop-types';

const propTypes = {
    changeStar: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    star: PropTypes.string.isRequired,
};

const Stars = ({star, changeStar, t}) => {
    const _changeStar = () => (e) => changeStar(e.target.value);

    const textStar = () => {
        switch (star) {
            case '5':
                return t('Отлично');
            case '4':
                return t('Супер');
            case '3':
                return t('Хорошо');
            case '2':
                return t('Удовлетворительно');
            case '1':
                return t('Плохо');
            default:
                return t('');
        }
    };

    return (
        <>
            <div className="rating__item">
                <div className="rating__stars">
                    {
                        [...Array(5)].map((i, n) => {
                            const val = 5 - n;
                            return (
                                <Fragment key={val}>
                                    <input type="radio"
                                           key={val}
                                           id={`star-${val}`}
                                           name="rating"
                                           value={val}
                                           onClick={_changeStar()}
                                           defaultChecked={val === +star}
                                    />
                                    <label htmlFor={`star-${val}`} title={`${t('Оценка')} «${val}»`}/>
                                </Fragment>
                            );
                        })
                    }
                </div>
            </div>
            <div className="rating__item">
                <span className="rating__text">{textStar()}</span>
            </div>
        </>
    );
};

Stars.propTypes = propTypes;

export default compose(
    withTagDefaultProps(),
)(Stars);