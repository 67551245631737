import React from 'react';
import { compose } from 'redux';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';
import Img from 'Templates/Img';

const propTypes = {
    onClickDelete: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const Attached = ({ onClickDelete, files }) => {
    if (!files.length > 0) return null;
    return (
        <div className="attach-file">
            {files.map((i, k) => (
                <div className="attach-file__block" key={k}>
                    <Img className="attach-file__img" img="image" alt="image" />
                    <button className="attach-file__btn-delete" type="button" onClick={() => onClickDelete(k)}>
                        <span className="attach-file__close">X</span>
                    </button>
                </div>
            ))}
        </div>
    );
};

Attached.propTypes = propTypes;

export default compose(withServiceConsumer, withTagDefaultProps(null, null))(Attached);
