import React from 'react';
import {compose} from "redux";
import {ServiceProvider} from "Services/Context";
import { withTagDefaultProps } from 'Hoc/Template';
import WaitingFormAnswer from "./Components/WaitingFormAnswer";
import * as service from "./Services";
import LoadPage from "Hoc/LoadPage";

const Index = () => {
    return (
        <ServiceProvider value={service}>
            <WaitingFormAnswer/>
        </ServiceProvider>
    )
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: service.getActionStore('pageLoad')(dispatch),
});

export default compose(withTagDefaultProps(null, mapDispatchToProps), LoadPage)(Index);