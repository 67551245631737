import React from 'react';
import { compose } from 'redux';
import { withTagDefaultProps } from 'Hoc/Template';
import { EmojiPicker } from 'Templates/EmojiPicker';
import PropTypes from 'prop-types';
import ButtonWrapper from 'Templates/Buttons/ButtonWrapper';

const propTypes = {
    t: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
    changeCloseChat: PropTypes.func.isRequired,
    closeChat: PropTypes.bool.isRequired,
};

const defaultProps = {
    inputProps: {},
};

const FooterIndex = ({
    t,
    message,
    onChange,
    onClick,
    onSubmit,
    inputProps,
    uploadComponent,
    changeCloseChat,
    disabled,
    closeChat,
}) => {
    const onEmoji = ({ native }) => onChange('message', `${message}${native}`);

    const onKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            if (!window.mobileAndTabletCheck()) {
                onSubmit();
            }
        }

        if (event.keyCode === 13) {
            if (window.mobileAndTabletCheck()) {
                onSubmit();
            }
        }
    };

    const _onSubmit = (event) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <form onSubmit={_onSubmit} className="main__footer">
            <div className="main__footer-item">
                <div className="form">
                    <textarea
                        className="main__footer-input"
                        placeholder={t('Введите своё сообщение ...')}
                        value={message}
                        name="message"
                        onChange={({ target }) => onChange('message', target.value)}
                        onKeyDown={onKeyDown}
                        {...inputProps}
                        autoFocus
                    />
                </div>
            </div>
            <div className="main__footer-item main__footer-item-buttons main__emoji">
                <EmojiPicker onSelect={onEmoji} />
                {uploadComponent}
            </div>
            <ButtonWrapper
                onClick={onClick}
                changeCloseChat={changeCloseChat}
                disabled={disabled}
                closeChat={closeChat}
            />
        </form>
    );
};

FooterIndex.propTypes = propTypes;
FooterIndex.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(FooterIndex);
