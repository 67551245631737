const Constants = {
    PAGE_LOADED: 'FORM_ANSWER_PAGE_LOADED',
    PAGE_UNLOADED: 'FORM_ANSWER_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'FORM_ANSWER_PAGE_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'FORM_ANSWER_PAGE_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'FORM_ANSWER_PAGE_FETCH_ITEMS_FAILED',

    CHANGE_PAGE: 'CHANGE_PAGE',
};

export default Constants;
