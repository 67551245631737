import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTagDefaultProps } from 'Hoc/Template';
import { withServiceConsumer } from 'Services/Context';
import { TypingMessage } from 'Templates/TypingMessage';

const propTypes = {
    service: PropTypes.shape({
        socketService: PropTypes.func.isRequired,
    }).isRequired,
};

const TypingWrap = ({ service, t }) => {
    const [state, setState] = useState({ key: '_default', data: '' });
    const { socketService } = service;
    const initState = ({ data: { client_id } }) => {
        if (!client_id) {
            setState({ ...state, key: '_default' });
        }
    };
    const typingListener = ({ data: { text } }) => {
        setState({ key: text ? 'typing' : '_default', data: text });
    };

    useEffect(() => {
        const init = async () => {
            const emitter = await socketService();
            emitter.chatOn('enter', typingListener);
            emitter.chatOn('send', initState);
            return emitter;
        };

        const emitter = init();

        return () => {
            emitter.then((data) => {
                data.remove('enter', typingListener);
                data.remove('send', initState);
            });
        };
    }, []);

    if (state.key === 'typing') {
        return <TypingMessage showAnimation message={t("Teenergizer пишет")}  />
    }
    return null
};

TypingWrap.propTypes = propTypes;

export default compose(withServiceConsumer, withTagDefaultProps(null, null))(TypingWrap);
