import React from 'react';
import { compose } from 'redux';
import { cond, T, equals } from 'ramda';
import { withTagDefaultProps } from 'Hoc/Template';
import Chat from './Chat/index';
import ChatSmall from './ChatSmall/index';
import Form from './Form/index';
import FormLater from './FormLater/index';
import FormReview from './FormReview/index';
import FormAnswer from './FormAnswer/index';
import Closed from './Closed/index';
import Regulations from './Regulations/index';
import 'Assets/scss/_main.scss';

const routing = cond([
    [equals('chat'), () => <Chat />],
    [equals('chatSmall'), () => <ChatSmall />],
    [equals('form'), () => <Form />],
    [equals('formReview'), () => <FormReview />],
    [equals('formAnswer'), () => <FormAnswer />],
    [equals('formLater'), () => <FormLater />],
    [equals('regulations'), () => <Regulations />],
    [equals('closed'), () => <Closed />],
    [T, () => <ChatSmall />],
]);

const Index = ({ url }) => routing(url);

const mapStateToProps = (state) => {
    return {
        url: state.appState.url,
    };
};

export default compose(withTagDefaultProps(mapStateToProps))(Index);
