import { PageActions } from '../Action';
import { AppActions } from 'App/Action';
import { getStoreItem as _getStoreItem } from 'Services';

export const getAppStoreItem = _getStoreItem('appState');
export const getUserStoreItem = _getStoreItem('userState');

export const getActionStore = (action) => {
    const actions = {
        ...PageActions,
    };

    return actions[action] || (() => {});
};

export const getActionAppStore = (action) => {
    const actions = {
        ...AppActions,
    };

    return actions[action] || (() => {});
};

export const getActionUserStore = (action) => {
    const actions = {
        ...AppActions,
    };

    return actions[action] || (() => {});
};

const getStoreItem = (stage, key, def) => _getStoreItem('pageState')(stage, key, def);

export default getStoreItem;
