import React from 'react';

const Dots = () => (
    <div className="dots">
        {Array.from(Array(4).keys()).map((i) => (
            <span key={i} />
        ))}
    </div>
);

export default Dots;
