import Constants from '../Constants';

export const pageLoad = (dispatch) => async () => {
    const data = {};

    dispatch({
        type: Constants.PAGE_LOADED,
        data,
    });
};

export const changeReview = (dispatch) => (review) =>
    dispatch({
        type: Constants.CHANGE_REVIEW,
        data: { review },
    });
