import React from 'react';
import logo from 'Assets/images/logo.png';
import { compose } from 'redux';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';

const propTypes = {
    changeRoute: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const WaitingFormAnswer = ({ changeRoute, t }) => {
    return (
        <div className="main">
            <div className="main__wrapper main__answer">
                <div className="main__waiting-item main__waiting-center">
                    <img className="main__header-img main__waiting-margin" src={logo} alt={t('Логотип')} />
                    <h3 className="main__waiting-title">{t('Спасибо за твой отзыв')}</h3>
                </div>
                <div className="main__waiting-item">
                    <button
                        className="form__btn"
                        type="submit"
                        onClick={() => {
                            changeRoute('chatSmall');
                        }}
                    >
                        {t('Начать еще одну консультацию')}
                    </button>
                </div>
            </div>
        </div>
    );
};

WaitingFormAnswer.propTypes = propTypes;

const mapStateToProps = (state) => ({
    ...state,
});

const mapDispatchToProps = (dispatch, { service: { getActionAppStore } }) => ({
    changeRoute: getActionAppStore('changeRoute')(dispatch),
});

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(WaitingFormAnswer);
