import ChatSmallReducer from 'Pages/ChatSmall/Reducers';
import ChatReducer from 'Pages/Chat/Reducers';
import FormReducer from 'Pages/Form/Reducers';
import FormReviewReducer from 'Pages/FormReview/Reducers';
import FormAnswerReducer from 'Pages/FormAnswer/Reducers';
import FormLaterReducer from 'Pages/FormLater/Reducers';
import RegulationsReducer from 'Pages/Regulations/Reducers';

const reducers = [ChatSmallReducer, ChatReducer, FormReducer, FormReviewReducer, FormAnswerReducer, FormLaterReducer, RegulationsReducer];

const reducer = (state = {}, action) => {
    reducers.forEach((page) => page.forEach((reducer) => (state = reducer(state, action))));
    return state;
};

export default reducer;
