import React, { useRef } from 'react';
import { Picker } from 'emoji-mart';
import PropTypes from 'prop-types';
import useClickOutside from 'Hooks/useClickOutside';
import 'emoji-mart/css/emoji-mart.css';

const propTypes = {
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

const defaultProps = {
  onClose: () => {},
}

const EmojiPopover = ({ onSelect, onClose }) => {
    const emojiWrapRef = useRef();

    useClickOutside(emojiWrapRef, onClose);

    return (
      <div ref={emojiWrapRef} className="emoji-mart__picker-wrapper">
          <Picker onSelect={onSelect} showPreview={false} showSkinTones={false} native isNative="true" />
      </div>
    );
};

EmojiPopover.propTypes = propTypes;
EmojiPopover.defaultProps = defaultProps;

export default EmojiPopover;
