import React from 'react';
import {compose} from 'redux';
import {ServiceProvider} from 'Services/Context';
import {LoadPage} from 'Hoc';
import { withTagDefaultProps } from 'Hoc/Template';
import WaitingFormReview from './Components/WaitingFormReview';
import * as service from './Services';

const Index = () => {

    return (
        <ServiceProvider value={service}>
            <WaitingFormReview/>
        </ServiceProvider>
    )
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: service.getActionStore('pageLoad')(dispatch),
});

export default compose(withTagDefaultProps(null, mapDispatchToProps), LoadPage)(Index);