import React, {useState} from 'react';
import {compose} from 'redux';
import {withTagDefaultProps} from 'Hoc/Template';
import json from './SingleLanguages.json';
import PropTypes from "prop-types";
import {i18n} from 'Services/i18n';
import Img from '../Img';

const items = json;

const propTypes = {
    changeLanguages: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const ListLanguages = ({ changeLanguages, t }) => {
    const [langWindow, setLangWindow] = useState(false);

    const _onClick = (e, str) => {
        setLangWindow(false);
        changeLanguages(str);
        i18n.change(str);
    };

    const currentLanguage = i18n.language();
    const checkIcon = (() => {
        switch (currentLanguage) {
            case "ua":
                return "ua";
            case "kaz":
                return "kaz";
            case "kgz":
                return "kgz";
            default:
                return "ru";
        }
    })();

    return (
        <>
            {langWindow && <div className={`${langWindow ? 'languages__window' : ''}`} onClick={() => setLangWindow(false)}/>}
            <div className="main__online-item" onClick={() => setLangWindow(true)}>
                <Img className="main__online-img" img={checkIcon} alt={t(checkIcon)}/>
                <svg className="main__online-arrow" width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.41463 0H0.583719L0.487946 0.0975207C-0.162649 0.74876 -0.162649 1.52727 0.487946 2.17851L3.81523 5.50909C4.13227 5.82645 4.55168 6 4.99918 6C5.44666 6 5.86773 5.82479 6.18312 5.50909L9.5104 2.17686C10.161 1.52562 10.161 0.747107 9.5104 0.0958678L9.41463 0Z"/>
                </svg>
            </div>
            <div className={`languages ${langWindow ? 'languages__block' : ''}`}>
                <div className="languages__wrapper">
                    {items.map((item, k) => (
                        <div key={k} className="languages__item" onClick={ (e) => { _onClick(e, item.lang) } }>
                            <span className="languages__text">{t(item.lang)}</span>
                            <Img className="languages__img" img={item.img} alt={t(item.alt)} />
                            {!k && (
                                <svg className="languages__svg" width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.583904 6L9.41482 6L9.51059 5.90248C10.1612 5.25124 10.1612 4.47273 9.51059 3.82149L6.18331 0.490909C5.86627 0.173553 5.44685 7.89162e-08 4.99936 3.97954e-08C4.55187 6.74597e-10 4.1308 0.175206 3.81541 0.490909L0.488133 3.82314C-0.162462 4.47438 -0.162462 5.25289 0.488132 5.90413L0.583904 6Z" fill="black"/>
                                </svg>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

ListLanguages.propTypes = propTypes;


export default compose(withTagDefaultProps())(ListLanguages);
