const Constants = {
    PAGE_LOADED: 'REGULATIONS_PAGE_LOADED',
    PAGE_UNLOADED: 'REGULATIONS_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'REGULATIONS_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'REGULATIONS_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'REGULATIONS_FETCH_ITEMS_FAILED',

    ROLES_ADD: 'ROLES_ADD',

    CHANGE_PAGE: 'CHANGE_PAGE',
};

export default Constants;
