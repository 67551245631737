import React from 'react';
import { compose } from 'redux';
import Navigation from './Navigation';
import logo from 'Assets/images/logo.png';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';

const propTypes = {
    switchWindow: PropTypes.func.isRequired,
    changeCloseChat: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const HeaderIndex = ({ t, switchWindow, changeCloseChat }) => {
    return (
        <div className="main__header">
            <div className="main__header-item">
                <img className="main__header-img" src={logo} alt={t('Logo')} />
                <span>{t('Teenergizer')}</span>
            </div>
            <Navigation switchWindow={switchWindow} changeCloseChat={changeCloseChat} />
        </div>
    );
};

HeaderIndex.propTypes = propTypes;

export default compose(withTagDefaultProps())(HeaderIndex);
