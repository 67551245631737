import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withTagDefaultProps } from 'Hoc/Template';

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    storageUrl: PropTypes.string.isRequired,
    onLoad: PropTypes.func.isRequired,
};

const FileBlock = ({ item, storageUrl, onLoad }) => {
    const url = `https://${process.env.HOST_NAME}${storageUrl}/${item.text}`;
    return (
        <div
            className={`main__body-message main__body-message_file ${
                item.client_id ? '' : 'main__body-consultant-message'
            }`}
        >
            <a href={url} className="main__body-message-link" target="_blank" rel="noreferrer">
                <img src={url} alt="preview-file" onLoad={onLoad} />
                <span className="link-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M488.727 0H302.545c-12.853 0-23.273 10.42-23.273 23.273s10.42 23.273 23.273 23.273h129.997L192.999 286.09c-9.087 9.087-9.087 23.823 0 32.912a23.2 23.2 0 0016.455 6.816 23.195 23.195 0 0016.455-6.817L465.455 79.458v129.997c0 12.853 10.42 23.273 23.273 23.273s23.273-10.42 23.273-23.273V23.273C512 10.42 501.58 0 488.727 0z" />
                        <path d="M395.636 232.727c-12.853 0-23.273 10.42-23.273 23.273v209.455H46.545V139.636H256c12.853 0 23.273-10.42 23.273-23.273S268.853 93.091 256 93.091H23.273C10.42 93.091 0 103.511 0 116.364v372.364C0 501.58 10.42 512 23.273 512h372.364c12.853 0 23.273-10.42 23.273-23.273V256c-.001-12.853-10.421-23.273-23.274-23.273z" />
                    </svg>
                </span>
            </a>
        </div>
    );
};

FileBlock.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        storageUrl: state.appState.storage_url,
    };
};

export default compose(withTagDefaultProps(mapStateToProps))(FileBlock);
