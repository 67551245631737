import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import HeaderSmallIndex from 'Templates/Header/HeaderSmallIndex';
import HeaderBottom from 'Templates/Header/HeaderBottom';
import FooterIndex from 'Templates/Footer/FooterIndex';
import button_icon_open from 'Assets/icons/icon-open-chat-button.svg';
import { clientCookies } from 'Services/Cookie';

const propTypes = {
    temporaryMessage: PropTypes.string.isRequired,
    consultantStatus: PropTypes.bool.isRequired,
    closeChat: PropTypes.bool.isRequired,
    changeRoute: PropTypes.func.isRequired,
    changeLanguages: PropTypes.func.isRequired,
    changeTemporaryMessage: PropTypes.func.isRequired,
    changeCloseChat: PropTypes.func.isRequired,
};

const GreetingHello = ({
    temporaryMessage,
    consultantStatus,
    closeChat,
    changeCloseChat,
    changeRoute,
    changeLanguages,
    changeTemporaryMessage,
}) => {
    const _changeCloseChat = () => changeCloseChat(!closeChat);
    const onChange = (key, value) => changeTemporaryMessage(value);
    const onClickChangeRoute = () => changeRoute(clientCookies.get() ? 'chat' : 'form');

    const disabledMessage = !!temporaryMessage.trim();

    const submit = () => {
        if (temporaryMessage.trim()) {
            onClickChangeRoute();
        }
    };

    return (
        <div className="main main__emoji">
            <div className={`main__wrapper main__small ${closeChat ? 'main__close' : ''}`}>
                <div>
                    <HeaderSmallIndex changeCloseChat={_changeCloseChat} />
                    <HeaderBottom changeLanguages={changeLanguages} consultantStatus={consultantStatus} />
                </div>
                <FooterIndex
                    message={temporaryMessage}
                    onChange={onChange}
                    onSubmit={submit}
                    onClick={onClickChangeRoute}
                    disabled={disabledMessage}
                    closeChat={closeChat}
                    changeCloseChat={_changeCloseChat}
                />
            </div>
            {closeChat && (
                <button className="main-circle-button__open" onClick={_changeCloseChat}>
                    <img src={button_icon_open} alt="images" />
                </button>
            )}
        </div>
    );
};

GreetingHello.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getAppStoreItem, getUserStoreItem } = ownProps.service;

    return {
        consultantStatus: getUserStoreItem(state, 'consultantStatus'),
        temporaryMessage: getUserStoreItem(state, 'temporaryMessage'),
        closeChat: getAppStoreItem(state, 'closeChat', ''),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { getActionStore, getActionAppStore, getActionUserStore } = ownProps.service;

    return {
        changeRoute: getActionAppStore('changeRoute')(dispatch),
        changeLanguages: getActionStore('changeLanguages')(dispatch),
        changeTemporaryMessage: getActionUserStore('changeTemporaryMessage')(dispatch),
        changeCloseChat: getActionAppStore('changeCloseChat')(dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps))(GreetingHello);
