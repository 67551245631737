import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from './Select';

const propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.object),
    ]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    inputProps: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    disabled: false,
    type: 'text',
    id: '',
    placeholder: '',
    value: '',
    url: '',
    inputProps: {},
    options: [],
};

const Input = ({ id, disabled, type, className, name, placeholder, value, onChange, options, inputProps }) => {
    className = `${className} ${_.get(inputProps, 'className', '')}`;
    _.unset(inputProps, 'className');

    const _onChange = (e) => {
        const value = _.get(e, 'target.value', _.get(e, 'value', e));
        onChange(value);
    };

    switch (type) {
        case 'select':
            return (
                <Select
                    disabled={disabled}
                    inputProps={inputProps}
                    value={value}
                    options={options}
                    name={id}
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            );

        default:
            return (
                <input
                    {...inputProps}
                    type={type}
                    disabled={disabled}
                    className={className}
                    name={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={_onChange}
                />
            );
    }
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
