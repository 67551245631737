import React from 'react';
import button_icon_send from 'Assets/icons/icon-send-message.svg';
import PropTypes from 'prop-types';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const SendMessageButton = ({disabled, onClick}) => {
    return (
        <button type="submit" disabled={!disabled} className="main-circle-button" onClick={onClick} style={{zIndex: 2}}>
            <img src={button_icon_send} alt="images"/>
        </button>
    );
};

SendMessageButton.propTypes = propTypes;

export default SendMessageButton;