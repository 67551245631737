import { useEffect } from "react";

function useClickOutside(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && typeof cb == 'function') {
        cb(event)
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, cb]);
}

export default useClickOutside;