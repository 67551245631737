import moment from 'moment';

export const formatDate = (date, format = 'DD.MM.YYYY') => {
    // const stillUtc = moment.utc(date).toDate();
    // return moment(stillUtc)
    //     .local()
    //     .format(format);
    return moment.utc(date).format(format);
};

export const formatDateTime = (date, format = 'HH:mm') => {
    const stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local().format(format);
};

export const formatDateAddUtcOffset = (date, format = 'll') => {
    // const stillUtc = moment.utc(date).toDate();
    // return moment(stillUtc)
    //     .local()
    //     .format(format);
    return moment.utc(date).add(moment().utcOffset(), 'minutes').format(format);
};

export const diffDate = (date, diffDate, unitOfTime = 'days') => {
    date = date ? moment(date) : moment();
    diffDate = diffDate ? moment(diffDate) : moment();

    return date.diff(diffDate, unitOfTime);
};

export const getHourMinFromTimeString = (value) => {
    const timeArr = value.split(':');
    timeArr.pop();
    return timeArr.join(':');
};

export const getExpire = (minutes = 1) => {
    const now = new Date();
    now.setTime(now.getTime() + minutes * 60_000);
    return now.toUTCString();
};
