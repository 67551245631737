import React from 'react';
import { compose } from 'redux';
import { ServiceProvider } from 'Services/Context';
import { LoadPage } from 'Hoc';
import { withTagDefaultProps } from 'Hoc/Template';
import WaitingForm from './Components/WaitingForm';
import * as service from './Services';

const Index = () => {
    return (
        <ServiceProvider value={service}>
            <WaitingForm />
        </ServiceProvider>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: service.getActionStore('pageLoad')(dispatch),
});

export default compose(withTagDefaultProps(null, mapDispatchToProps), LoadPage)(Index);
