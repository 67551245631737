import React from 'react';
import PropTypes from 'prop-types';
import {Dots} from '../Dots';

const propTypes = {
  message: PropTypes.string.isRequired,
  showAnimation: PropTypes.bool,
};

const defaultProps = {
  showAnimation: false,
};

const TypingMessage = ({message, showAnimation}) => (
  <div className='main__write-message'>
    {showAnimation && <Dots />}
    <span>{message}</span>
  </div>
)

TypingMessage.propTypes = propTypes;
TypingMessage.defaultProps = defaultProps;

export default TypingMessage;