import { request } from 'Services';

const receive = (endpointUrl) => {
    const sendObj = {
        url: endpointUrl,
        type: 'GET',
    };
    return request.sendRequest(sendObj, false);
};

const requestCreate = (requestUrl) => {
    return new Promise((resolve, reject) => {
        receive(requestUrl).then(
            (res) => resolve({ ...res[0] }),
            (error) => reject(error),
        );
    });
};

export const createRules = requestCreate(`rule`);