import React from 'react';
import { compose } from 'redux';
import { ServiceProvider } from 'Services/Context';
import { LoadPage } from 'Hoc';
import { withTagDefaultProps } from 'Hoc/Template';
import * as service from './Services';
import FormLater from './Components/FormLater';

const Index = () => {
    return (
        <ServiceProvider value={service}>
            <FormLater />
        </ServiceProvider>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: service.getActionStore('pageLoad')(dispatch, service),
});

export default compose(withTagDefaultProps(null, mapDispatchToProps), LoadPage)(Index);
