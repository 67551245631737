const settings = {
    value: localStorage.getItem("i18n") || "ua",

    valueOf() {
        return settings.value;
    },

    toString() {
        return settings.valueOf();
    },

    change(lang) {
        settings.value = lang;
        localStorage.setItem("i18n", lang);
    }
};

const dictionaries = () => require(`./translations/${settings}/translation.json`);

const dictionary = (word) => {
    const list = dictionaries();
    return list[word] || word;
}

export {
    settings,
    dictionary
};
