import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTagDefaultProps } from 'Hoc/Template';
import { withServiceConsumer } from 'Services/Context';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const acceptFileFormats = ['.jpg', '.jpeg', '.png', '.bmp', '.gif', '.heic'];
const acceptFormats = acceptFileFormats.join(', ');
const fileFormatRegex = new RegExp(`(\\${acceptFileFormats.join('|\\')})$`)

const UploadFileBlock = ({ onChange, files }) => {
    const fileRef = useRef();
    const handleClickOnUploadFile = () => fileRef.current.click();

    const handleDrop = (data) => {
        const maxLength = 5;
        if (files.length + data.length > maxLength) {
            alert(`Maximum file length ${maxLength}`);
            return;
        }

        onChange([...files, ...data]);
    };

    const formatData = (data) => {
        const maxSize = 25;
        for (let i = 0; i < data.length; i += 1) {
            const file = data[i];
            if (file.size >= maxSize * 1024 * 1024) {
                alert(`Maximum file size ${maxSize}`);
                return;
            }

            if (!fileFormatRegex.test(file.name)) {
                alert(`Allowed file formats: ${acceptFormats}`);
                return;
            }
        }

        handleDrop(data);
    };

    const _onChange = (e) => {
        formatData(e.target.files);
    };

    return (
        <div className="attach-file">
            <button type="button" className="attach-file__btn" onClick={handleClickOnUploadFile}>
                <svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.43354 20.0009C4.88951 20.0009 3.34547 19.4592 2.12519 18.3757C0.855096 17.243 0.107982 15.667 0.00836709 13.9679C-0.0663442 12.2688 0.506443 10.6436 1.67692 9.38777L9.89517 0.424464C10.2189 0.0550974 10.8166 0.0304729 11.1653 0.375216C11.5388 0.719958 11.5637 1.28632 11.2151 1.63106L2.99682 10.5944C2.175 11.5055 1.75163 12.6628 1.80144 13.8941C1.85125 15.1006 2.39913 16.2334 3.32057 17.046C5.18835 18.7204 8.07719 18.5727 9.79555 16.7751L19.4333 6.50671C19.9563 5.96497 20.2302 5.25086 20.2053 4.4875C20.1804 3.74877 19.8567 3.03466 19.2839 2.54217C18.736 2.04968 18.0138 1.77881 17.2418 1.80343C16.4947 1.82806 15.7974 2.14818 15.2744 2.71454L6.35883 12.4166C6.1596 12.6382 6.05999 12.9091 6.08489 13.2046C6.10979 13.5001 6.23431 13.7463 6.45845 13.9433C6.90671 14.3127 7.52931 14.2634 7.92777 13.8448L15.4736 5.8911C15.8223 5.52173 16.3951 5.52173 16.7437 5.84185C17.1173 6.18659 17.1173 6.75295 16.7935 7.0977L9.24767 15.076C8.20171 16.1841 6.45845 16.2826 5.28797 15.3223C4.69028 14.8298 4.31672 14.1157 4.26691 13.3277C4.21711 12.5397 4.46614 11.801 5.01403 11.21L13.9545 1.50794C14.8012 0.596836 15.9219 0.0797218 17.1671 0.00584841C18.4123 -0.0434005 19.5827 0.375215 20.5042 1.21245C21.4256 2.04968 21.9486 3.18241 21.9984 4.41363C22.0482 5.64485 21.5999 6.82683 20.7532 7.71331L11.1404 17.9817C9.89517 19.3361 8.1519 20.0009 6.43354 20.0009Z" />
                </svg>
            </button>
            <input
                ref={fileRef}
                type="file"
                value=""
                className="visually-hidden"
                // accept=".jpg, .jpeg, .png, .bmp, .gif, .heic"
                accept="image/*;capture=camera"
                multiple
                onChange={_onChange}
            />
        </div>
    );
};

UploadFileBlock.propTypes = propTypes;

export default compose(withServiceConsumer, withTagDefaultProps(null, null))(UploadFileBlock);
