import Constants from '../Constants';

export const pageLoad = (dispatch, service) => async () => {
    const data = await service.createRules;
    dispatch({
        type: Constants.PAGE_LOADED,
        data,
    });
};

