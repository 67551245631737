import React from 'react';
import { compose } from 'redux';
import button_icon_open from 'Assets/icons/icon-open-chat-button.svg';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';
import HeaderIndex from 'Templates/Header/HeaderIndex';
import HeaderBottom from 'Templates/Header/HeaderBottom';
import { Chat, ChatForm } from './Sections';
import Stars from 'Templates/Star/Stars';

const propTypes = {
    switchWindow: PropTypes.func.isRequired,
    changeCloseChat: PropTypes.func.isRequired,
    changeCloseStar: PropTypes.func.isRequired,
    changeStar: PropTypes.func.isRequired,
    star: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    resizeChat: PropTypes.bool.isRequired,
    closeChat: PropTypes.bool.isRequired,
    closeStar: PropTypes.bool.isRequired,
    consultantStatus: PropTypes.bool.isRequired,
};

const Greeting = ({
    switchWindow,
    changeCloseChat,
    changeCloseStar,
    closeStar,
    changeStar,
    resizeChat,
    consultantStatus,
    closeChat,
    star,
}) => {
    const _changeCloseChat = () => changeCloseChat(!closeChat);
    const _changeCloseStar = () => changeCloseStar(!closeStar);
    const _switchWindow = () => switchWindow(!resizeChat);

    const classChat = `${resizeChat ? 'main__full' : ''} ${closeChat ? 'main__close' : ''}`;
    const classStar = `${closeStar ? 'active' : ''}`;

    return (
        <div className="main">
            <div className={`main__wrapper ${classChat}`}>
                <HeaderIndex switchWindow={_switchWindow} changeCloseChat={_changeCloseChat} />
                <HeaderBottom consultantStatus={consultantStatus} />
                <div className={`main__rating rating ${classStar}`}>
                    <Stars changeStar={changeStar} star={star} />
                    <div className="main__star-close">
                        <div onClick={_changeCloseStar}>
                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.60778 8.0908L13.7485 1.86147C14.0838 1.4988 14.0838 0.944135 13.7485 0.602802C13.4132 0.261468 12.8473 0.261468 12.512 0.602802L7 6.21347L1.48802 0.602802C1.13174 0.261468 0.586826 0.261468 0.251497 0.602802C-0.0838323 0.944135 -0.0838323 1.4988 0.251497 1.86147L6.37126 8.0908C6.72755 8.43213 7.27246 8.43213 7.60778 8.0908Z" fill="black"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <Chat />
                <ChatForm changeCloseChat={_changeCloseChat} closeChat={closeChat} />
            </div>
            {closeChat && (
                <button className="main-circle-button__open" onClick={_changeCloseChat}>
                    <img src={button_icon_open} alt="images" />
                </button>
            )}
        </div>
    );
};

Greeting.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getAppStoreItem, getUserStoreItem } = ownProps.service;
    return {
        resizeChat: getAppStoreItem(state, 'resizeChat'),
        consultantStatus: getUserStoreItem(state, 'consultantStatus'),
        closeChat: getAppStoreItem(state, 'closeChat'),
        closeStar: getAppStoreItem(state, 'closeStar'),
        star: getUserStoreItem(state, 'star'),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { getActionStore, getActionAppStore } = ownProps.service;
    return {
        switchWindow: getActionStore('switchWindow')(dispatch),
        changeCloseChat: getActionAppStore('changeCloseChat')(dispatch),
        changeCloseStar: getActionAppStore('changeCloseStar')(dispatch),
        changeStar: getActionAppStore('changeStar')(dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps))(Greeting);
