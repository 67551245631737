import React from 'react';
import button_icon from 'Assets/icons/icon-open-chat-button.svg';
import { compose } from 'redux';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from "prop-types";
import {changeRoute} from "App/Action/PageAction";

const propTypes = {
    changeRoute: PropTypes.func.isRequired,
};

const OpenChatButton = ({ changeRoute }) => {
    return (
        <button type="button" className="main-circle-button main-circle-button__open-chat" onClick={() => changeRoute('chatSmall')}>
            <img src={button_icon} alt="resize chat" />
        </button>
    );
};

OpenChatButton.propTypes = propTypes;


const mapStateToProps = (state) => ({
    ...state,
});

const mapDispatchToProps = (dispatch) => ({
    changeRoute: changeRoute(dispatch)
});

export default compose(withTagDefaultProps(mapStateToProps, mapDispatchToProps))(OpenChatButton);
