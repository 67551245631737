import React from "react";
import {compose} from "redux";
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from "prop-types";

const propTypes = {
    changeReview: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const Review = ({changeReview, t}) => {
    const _changeReview = () => (e) => changeReview(e.target.value);

    return (
        <form className="form">
            <label className="form__label" htmlFor="review">
                {t('Есть что сказать')}
            </label>
            <textarea className="main__waiting-review" id="review" placeholder={t('Твой отзыв')} onChange={_changeReview()} />
        </form>
    )
}

Review.propTypes = propTypes;

export default compose(
    withTagDefaultProps(),
)(Review);