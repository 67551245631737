const Constants = {
    PAGE_LOADED: 'CHAT_SMALL_PAGE_LOADED',
    PAGE_UNLOADED: 'CHAT_SMALL_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'CHAT_SMALL_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'CHAT_SMALL_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'CHAT_SMALL_FETCH_ITEMS_FAILED',

    CHANGE_FORM: 'CHAT_SMALL_PAGE_CHANGE_FORM',

    CHANGE_PAGE: 'CHANGE_PAGE',
    CHANGE_LANG: 'CHANGE_LANG',
};

export default Constants;
