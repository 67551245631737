import { changeRoute } from 'App/Action/PageAction';
import { consultationStatus } from 'Services/enum';
import Constants from '../Constants';
import { clientCookies, consultationCookies } from 'Services/Cookie';

export const pageLoad = (dispatch, service) => async () => {
    const { socketService, requestMessages, createConsultationRequest } = service;

    const fetchMessages = async (clientId, consultationId) => {
        const data = await requestMessages({ client_id: clientId }, consultationId);

        dispatch({
            type: Constants.PAGE_LOADED,
            data: { messages: data },
        });

        return consultationId;
    };

    const createConsultation = async (clientId) => {
        const newConsultation = await createConsultationRequest({ clientId });
        consultationCookies.set(newConsultation.id);

        dispatch({
            type: Constants.PAGE_LOADED,
            data: {},
        });

        return newConsultation.id;
    };

    const validateConsultation = async (consultationId, clientId) => {
        const req = await socketService();
        const { status } = await req.getInfo({ id: consultationId, client_id: clientId });

        if (status === consultationStatus.idle) {
            return fetchMessages(clientId, consultationId);
        }

        if (![consultationStatus.open, consultationStatus.active].includes(status)) {
            consultationCookies.delete();
            return createConsultation(clientId);
        }

        return fetchMessages(clientId, consultationId);
    };

    const getConsultation = async (clientId) => {
        const consultationId = consultationCookies.get();

        return consultationId ? validateConsultation(consultationId, clientId) : createConsultation(clientId);
    };

    const clientId = clientCookies.get();
    const consultationId = await getConsultation(clientId);

    dispatch({
        type: Constants.CHANGE_USER_DATA,
        data: { consultationId },
    });
};

export const switchWindow = (dispatch) => (resizeChat) =>
    dispatch({
        type: Constants.RESIZE_CHAT,
        data: { resizeChat },
    });

export const changeLanguages = (dispatch) => (langPage) => {
    dispatch({
        type: Constants.CHANGE_LANG,
        data: { langPage },
    });
};

export const messagesUpdate = (dispatch) => async (data) => {
    if (Array.isArray(data)) {
        dispatch({ type: Constants.NEW_FILE_MESSAGE_CHAT, data });
    } else {
        dispatch({ type: Constants.NEW_MESSAGE_CHAT, data });
    }
};

export const sendFiles = (dispatch, service) => (data, path) => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED });

    const { requestUploadFiles } = service;

    return new Promise((resolve, reject) => {
        requestUploadFiles(data, path).then(
            (res) => {
                resolve(res.items);
            },
            (errors) => {
                dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: { items: [] } });
                reject(errors);
            },
        );
    });
};

export const chatEventListener =
    (dispatch) =>
    ({ data: { type } }) => {
        switch (type) {
            case 'close':
                return changeRoute(dispatch)('formReview');

            default:
                return '';
        }
    };
