import React from 'react';
import { compose } from 'redux';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import PropTypes from 'prop-types';
import _ from 'lodash';

const propTypes = {
    changeRoute: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

const Regulations = ({ changeRoute, description, t }) => {

    return (
        <div className="regulations">
            <div className="regulations__inner">
                <div className="regulations__wrapper">
                    <div className="regulations__title">{t('Правила и Условия пользования сайтом')}</div>
                    <div className="regulations__block" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <button type="button" className="regulations__close" onClick={() => changeRoute('form')}>
                    <span />
                </button>
            </div>
        </div>
    );
};

Regulations.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    const items = getStoreItem(state, 'items', []);

    const description = items.filter(i => i.language === state.appState.langPage);

    return {
        description: _.get(description, `0.description`, ''),
    }
};

const mapDispatchToProps = (dispatch, { service: { getActionAppStore } }) => ({
    changeRoute: getActionAppStore('changeRoute')(dispatch),
});

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps))(Regulations);
