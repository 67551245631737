import React from 'react';
import button_icon_open from 'Assets/icons/icon-open-chat-button.svg';
import PropTypes from 'prop-types';

const propTypes = {
    onClick: PropTypes.func.isRequired,
};

const OpenMessageButton = ({onClick}) => {
    return (
        <button type="button" className="main-circle-button" onClick={onClick}>
            <img src={button_icon_open} alt="images"/>
        </button>
    );
};

OpenMessageButton.propTypes = propTypes;

export default OpenMessageButton;