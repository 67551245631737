import { mergeDeepLeft } from 'ramda';
import _ from 'lodash';
import Constants from '../Constants';

const initState = {
    loading: false,
    messages: {
        items: [],
        pagination: {},
    },
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.PAGE_LOADED:
            return {
                ...initState,
                ...data,
            };

        case Constants.FETCH_ITEMS_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case Constants.FETCH_ITEMS_SUCCESSED:
            return {
                ...state,
                ...data,
                loading: false,
            };

        case Constants.FETCH_ITEMS_FAILED:
            return {
                ...state,
                loading: false,
            };

        case Constants.CHANGE_FORM:
            const set = mergeDeepLeft({
                [data.key]: data.value,
            });
            const newState = set(state);

            return {
                ...state,
                ...newState,
            };

        case Constants.NEW_MESSAGE_CHAT:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: [...state.messages.items, data],
                },
                loading: false,
            };

        case Constants.NEW_FILE_MESSAGE_CHAT:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: [...state.messages.items, ...data],
                },
                loading: false,
            };

        case Constants.SEND_MESSAGE:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: [...state.messages.items, data],
                },
            };

        case Constants.FETCH_CHAT_ITEMS_SUCCEEDED:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    items: _.concat(data.items, state.messages.items),
                    pagination: data.pagination ? data.pagination : state.messages.pagination,
                },
                loading: false,
            };

        default:
            return state;
    }
};
