import Constants from '../Constants';

export const pageLoad = (dispatch) => async () => {
    const data = {};

    dispatch({
        type: Constants.PAGE_LOADED,
        data,
    });
};

export const onChange = (dispatch) => (key, value) => {
    dispatch({
        type: Constants.CHANGE_FORM,
        data: { key, value },
    });
};

export const changeLanguages = (dispatch) => (langPage) => {
    dispatch({
        type: Constants.CHANGE_LANG,
        data: { langPage },
    });
};
