import UserConst from 'App/Constants'

const Constants = {
    PAGE_LOADED: 'FORM_PAGE_PAGE_LOADED',
    PAGE_UNLOADED: 'FORM_PAGE_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'FORM_PAGE_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'FORM_PAGE_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'FORM_PAGE_FETCH_ITEMS_FAILED',

    CHANGE_FORM: UserConst.CHANGE_FORM,
    CHANGE_USER_DATA: UserConst.CHANGE_USER_DATA,

    CHANGE_LANG: UserConst.CHANGE_LANG,

    CHANGE_PAGE: 'CHANGE_PAGE',
};

export default Constants;
