const Constants = {
    PAGE_LOADED: 'CHAT_PAGE_LOADED',
    PAGE_UNLOADED: 'CHAT_PAGE_UNLOADED',

    FETCH_ITEMS_REQUESTED: 'CHAT_FETCH_ITEMS_REQUESTED',
    FETCH_ITEMS_SUCCESSED: 'CHAT_FETCH_ITEMS_SUCCESSED',
    FETCH_ITEMS_FAILED: 'CHAT_FETCH_ITEMS_FAILED',

    CHANGE_FORM: 'CHAT_PAGE_CHANGE_FORM',

    CHANGE_PAGE: 'CHANGE_PAGE',
    CHANGE_LANG: 'CHANGE_LANG',
    CHANGE_USER_DATA: 'CHANGE_USER_DATA',

    RESIZE_CHAT: 'RESIZE_CHAT',

    SEND_MESSAGE: 'SEND_MESSAGE',
    CLEAR_MESSAGE: 'CLEAR_MESSAGE',

    FETCH_CHAT_ITEMS_REQUESTED: 'FETCH_CHAT_ITEMS_REQUESTED',
    FETCH_CHAT_ITEMS_SUCCEEDED: 'FETCH_CHAT_ITEMS_SUCCEEDED',
    FETCH_CHAT_ITEMS_FAILED: 'FETCH_CHAT_ITEMS_FAILED',

    NEW_MESSAGE_CHAT: 'NEW_MESSAGE_CHAT',
    NEW_FILE_MESSAGE_CHAT: 'NEW_FILE_MESSAGE_CHAT',
};

export default Constants;
