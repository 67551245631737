import React from 'react'
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from 'Hoc/Template';


const propTypes = {
    switchWindow: PropTypes.func.isRequired,
    changeCloseChat: PropTypes.func.isRequired,
};

const Navigation = ({ switchWindow, changeCloseChat}) => {
    return (
        <div className="main__header-item">
            <div className="main__header-block" onClick={switchWindow}>
                <svg className="main__header-resize" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.6863 1.95153H7.50223V0.346802H15.9892C16.7092 0.346802 17.2911 0.928689 17.2911 1.64864V13.2508C17.2911 13.9751 16.7028 14.5487 15.9892 14.5487H1.30196C0.590487 14.5487 0.000123978 13.9792 0.000123978 13.2508V7.84891H1.60485V12.9439H15.6863V1.95153ZM2.73972 1.95153H5.4962V0.346802H0V5.843H1.60473V3.08597L6.51779 7.99903L7.6525 6.86432L2.73972 1.95153Z" fill="white"/>
                </svg>
            </div>
            <div className="main__header-block" onClick={changeCloseChat}>
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.60778 8.0908L13.7485 1.86147C14.0838 1.4988 14.0838 0.944135 13.7485 0.602802C13.4132 0.261468 12.8473 0.261468 12.512 0.602802L7 6.21347L1.48802 0.602802C1.13174 0.261468 0.586826 0.261468 0.251497 0.602802C-0.0838323 0.944135 -0.0838323 1.4988 0.251497 1.86147L6.37126 8.0908C6.72755 8.43213 7.27246 8.43213 7.60778 8.0908Z" fill="white"/>
                </svg>
            </div>
        </div>
    )
}

Navigation.propTypes = propTypes;


export default compose(
    withTagDefaultProps()
)(Navigation);